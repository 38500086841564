* {
  box-sizing: border-box;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 20px;
}

#root {
  margin: 0;
  padding: 0px;
  text-align: center;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0px;
  background-color: rgb(245, 245, 245);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  line-height: 1.6;
  font-size: 18px;
  font-weight: 500;
}

.content-container {
  max-width: 1200px;
  margin: 0 auto; /* Center the container */
}

.background-container {
    position: relative;
    width: 100%;
    max-height: 80vh;
    overflow: hidden;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay-content {
    position: absolute;
    bottom: 20px; /* Push the buttons 20px from the bottom */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

.overlay-button {
  background-color: rgba(143, 188, 143, 0.5);
  padding: 0.5rem .5rem; 
  border-radius: 5px; 
  color: black;
  text-decoration: none;
  font-weight: bold;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
}

.overlay-button:hover {
    background-color: #7FA07F;
}

.vertical-line {
    width: 2px;
    height: 40px;
    background-color: white;
    margin: 0 20px;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.text-title {
    font-size: 32px;
    color: #333;
    margin-bottom: 16px;
    text-align: center;
}

.text-divider {
    border: 0;
    height: 2px;
    background: #ddd;
    width: 80%;
    margin: 16px 0;
}

.text-body {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    text-align: center;
    max-width: 95%;
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
    word-break: break-word;
    hyphens: auto;
}

.cards-container {
    display: grid;
    gap: 20px;
    justify-content: center;
    width: 95%;
    margin: 0 auto;
    box-sizing: border-box;
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
}

.card-content {
    padding: 10px;
}

.card-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.card-description {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}

.card-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #8FBC8F;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
}

.card-button:hover {
    background-color: #7FA07F;
}

@media (min-width: 800px) {
    .cards-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 800px) {
    .cards-container {
        grid-template-columns: 1fr; /* Each card takes full width */
    }

    .card {
        width: 95%;
        margin: 0 auto; /* Center the card */
    }
}

@media (max-width: 600px) {
    .text-title {
        font-size: 24px;
    }

    .text-body {
        font-size: 16px;
        padding: 0 10px;
        line-height: 1.4;
    }

    .text-divider {
        width: 90%;
    }
}